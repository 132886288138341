import React, { useEffect, useState } from "react";
import { message, Modal, Table, Tabs, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import {
  CheckOutlined,
  ControlOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LinkOutlined,
  MessageOutlined,
  PlusOutlined,
  ShareAltOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import {
  deleteInterview,
  duplicateInterview,
  getInterviews,
  updateInterview,
} from "../../api/interviews";
import LoadingScreen from "../../components/Common/LoadingScreen";
import DuplicateButton from "../../components/Interview/DuplicateButton";

// const InviteMsg = ({uuid}) => {
//     return <p>Link copied to clipboard <br/>{process.env.REACT_APP_FRONTEND_URL}/async-interview/{uuid}/invite</p>;
// }

export const InterviewListPage = () => {
  const [interviews, setInterviews] = useState([]);
  const [events, setEvents] = useState([]);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const [isLoading, setIsLoading] = useState(false);

  const handleInvite = (record) => {
    navigator.clipboard.writeText(
      process.env.REACT_APP_FRONTEND_URL +
        "/async-interview/" +
        record.uuid +
        "/invite"
    );
    message.success("Link copied to clipboard");
    // message.success({
    //     key: 'invite', content: <InviteMsg uuid={record.uuid}/>, duration: '2',
    // });
    // message.loading({key: "invite", content: "sending invite..."});
    // interviewInvite()
    //     .then(({data}) => {
    //         navigator.clipboard.writeText(
    //             process.env.REACT_APP_FRONTEND_URL +
    //             "/interview/submit/" +
    //             record.uuid
    //         );
    //         message.success({key: "invite", content: "Link copied to clipboard"});
    //     })
    //     .catch((err) => {
    //         message.error({key: "invite", content: "Error inviting"});
    //     });
  };
  const columns = [
    {
      title: "Async Interview Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <div className="text-base font-medium">
          <Link
            to={"/async-interview/" + record.id}
            className="text-primary-500"
          >
            {record.title}
          </Link>
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created",
      key: "created",
      sorter: (a, b) =>
        new Date(a.created).getTime() - new Date(b.created).getTime(),
      render: (text) => (
        <div className="text-base text-gray-400">
          {moment(text).format("lll")}
        </div>
      ),
      width: "15%",
    },
    {
      title: <div className="text-center">Status</div>,
      dataIndex: "is_active",
      key: "is_active",
      width: "10%",
      sorter: (a, b) => {
        if (a.is_active && !b.is_active) return -1;
        if (!a.is_active && b.is_active) return 1;
        return 0;
      },
      render: (text, record) => (
        <div className="text-center">
          {record.is_active ? (
            <Tag
              className="text-center"
              color="green"
              style={{ width: "60px" }}
            >
              Active
            </Tag>
          ) : (
            <Tag
              className="text-center"
              color="volcano"
              style={{ width: "60px" }}
            >
              Inactive
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: " ",
      dataIndex: "is_active",
      key: "is_active",
      width: "10%",
      render: (text, record) => (
        <div className="flex justify-center space-x-2">
          {record.is_active && (
            <>
              <Tooltip title="Invite" color="#1945a4" key={record.uuid + "1"}>
                <button
                  className="btn-icon hover-blue"
                  onClick={() => {
                    handleInvite(record);
                  }}
                >
                  <ShareAltOutlined />
                </button>
              </Tooltip>
              <DuplicateButton
                key={record.uuid + "2"}
                record={{
                  id: record.id,
                  title: record.title,
                  uuid: record.uuid,
                }}
                handleDuplicate={handleDuplicate}
              />
              <Tooltip
                title="Delete Interview"
                color="#FF4D4D"
                key={record.uuid + "3"}
              >
                <button
                  className="btn-icon hover-red"
                  type="danger"
                  onClick={() => {
                    Modal.confirm({
                      title: "Confirm",
                      centered: true,
                      icon: <ExclamationCircleOutlined />,
                      content: "Do you want to delete",
                      okText: "Yes",
                      cancelText: "Cancel",
                      onOk: () => {
                        handleDelete(record.id);
                      },
                    });
                  }}
                >
                  <DeleteOutlined />
                </button>
              </Tooltip>
            </>
          )}
          {record.is_deleted && (
            <Tooltip
              title="Activate Interview"
              color="#FF8C00"
              key={record.uuid + "5"}
            >
              <button
                className="btn-icon hover-orange"
                onClick={() => {
                  Modal.confirm({
                    title: "Confirm",
                    centered: true,
                    icon: <ExclamationCircleOutlined />,
                    content: "Do you want to activate",
                    okText: "Yes",
                    cancelText: "Cancel",
                    onOk: () => {
                      handleActivate(record.id, record);
                    },
                  });
                }}
              >
                <CheckOutlined />
              </button>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];
  const eventColumns = [
    {
      title: "Sync Interview Title",
      dataIndex: "name",
      key: "name",
      width: "15%",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <div className="text-base font-medium text-primary-500">
          {record.name}
        </div>
      ),
    },
    {
      title: "Started At",
      dataIndex: "start_date_time",
      key: "start_date_time",
      sorter: (a, b) =>
        new Date(a.start_date_time).getTime() -
        new Date(b.start_date_time).getTime(),
      render: (text) => (
        <div className="text-base text-gray-400">
          {moment(text).format("lll")}
        </div>
      ),
      width: "15%",
    },
    {
      title: "Ended At",
      dataIndex: "end_date_time",
      key: "end_date_time",
      sorter: (a, b) =>
        new Date(a.end_date_time).getTime() -
        new Date(b.end_date_time).getTime(),
      render: (text) => (
        <div className="text-base text-gray-400">
          {moment(text).format("lll")}
        </div>
      ),
      width: "15%",
    },
    {
      title: "Status",
      dataIndex: "is_deleted",
      key: "is_deleted",
      width: "6%",
      sorter: (a, b) => {
        if (a.is_deleted && !b.is_deleted) return 1;
        if (!a.is_deleted && b.is_deleted) return -1;
        return 0;
      },
      render: (text, record) => (
        <div>
          {record.is_deleted ? (
            <Tag
              className="text-center"
              color="volcano"
              style={{ width: "60px" }}
            >
              Inactive
            </Tag>
          ) : (
            <Tag
              className="text-center"
              color="green"
              style={{ width: "60px" }}
            >
              Active
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: " ",
      dataIndex: "is_deleted",
      key: "is_deleted",
      width: "12%",
      render: (text, record) => (
        <div className="flex justify-around items-center">
          <Tooltip title="Event Link" color="#1945a4" key={record.uuid + "7"}>
            <button
              onClick={() => {
                navigator.clipboard.writeText(record.event_link);
                message.success("Link copied to clipboard");
              }}
              className="btn-icon px-6 py-2 hover-blue"
            >
              <LinkOutlined />
            </button>
          </Tooltip>
          <Tooltip
            title="View Participants"
            color="#ff8c00"
            key={record.uuid + "10"}
          >
            <Link
              to={`/sync-interview/${record.id}/room/${record.room_id}/participants`}
              className="btn-icon hover-orange"
            >
              <UserOutlined />
            </Link>
          </Tooltip>
          <Tooltip
            title="View Messages"
            color="#21BCAB"
            key={record.uuid + "11"}
          >
            <Link
              to={`/sync-interview/${record.id}/room/${record.room_id}/messages`}
              className="btn-icon hover-green"
            >
              <MessageOutlined />
            </Link>
          </Tooltip>
          <Tooltip
            title="View Attachments"
            color="#FF4D4D"
            key={record.uuid + "12"}
          >
            <Link
              to={`/sync-interview/${record.id}/room/${record.room_id}/attachments`}
              className="btn-icon hover-red"
            >
              <VideoCameraOutlined />
            </Link>
          </Tooltip>
          <Tooltip
            title="View Controls"
            color="#7e51b5"
            key={record.uuid + "14"}
          >
            <Link
              to={`/sync-interview/${record.id}/room/${record.room_id}/controls`}
              className="btn-icon hover-purple"
            >
              <ControlOutlined />
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleReload = () => {
    setReload(!reload);
  };

  const handleActivate = (uuid, data) => {
    message.loading({ key: "active", content: "Activating Interview" });
    let temp = { ...data, is_active: true, is_deleted: false };
    delete temp["organization_id"];
    updateInterview(uuid, temp)
      .then((data) => {
        message.success({ key: "active", content: data.message ?? "Activated" });
        handleReload();
      })
      .catch((err) => {
        message.error({ key: "active", content: err ?? "Error" });
        handleReload();
      });
  };
  const handleDuplicate = (id, title) => {
    message.loading({ key: "duplicate", content: "Duplicating interview" });
    duplicateInterview({'id': id, 'title': title})
      .then((data) => {
        message.success({ key: "duplicate", content: "Duplicated" });
        handleReload();
      })
      .catch((err) => {
        message.error({ key: "duplicate", content: err ?? "Error" });
        handleReload();
      });
  };
  const handleDelete = (uuid) => {
    message.loading({ key: "delete", content: "Deleting interview" });
    deleteInterview(uuid)
      .then((data) => {
        message.success({ key: "delete", content: data.message ?? "Deleted" });
        handleReload();
      })
      .catch((err) => {
        message.error({ key: "delete", content: err ?? "Error" });
        handleReload();
      });
  };

  useEffect(() => {
    localStorage.getItem("activeTab") &&
      setActiveTab(localStorage.getItem("activeTab"));
  }, []);

  const handleTabChange = (key) => {
    setActiveTab(key);
    localStorage.setItem("activeTab", key);
  };
  useEffect(() => {
    async function fetchData() {
      localStorage.getItem("api_key");
      setIsLoading(true);
      let interviews = [];
      try {
        const activeData = await getInterviews();

        const deletedData = await getInterviews("deleted");
        const completeInterviewData = [
          ...activeData.data.interviews,
          ...deletedData.data.interviews,
        ];
        const completeEventData = [
          ...activeData.data.events,
          ...deletedData.data.events,
        ];
        setEvents(completeEventData);
        setInterviews(completeInterviewData);
        setIsLoading(false);
      } catch (err) {
        message.error({
          key: "loading",
          content: "Error loading the Interviews",
        });
        setIsLoading(false);
      }
    }

    fetchData();
  }, [reload]);
  const { TabPane } = Tabs;
  //slug
  return (
    <div>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8">
        <Typography.Text className="text-2xl font-bold">
          List of {activeTab < 2 ? "Async Interviews" : "Sync Interviews"}
        </Typography.Text>
        {activeTab < 3 && (
          <Link
            to="/async-interview/create"
            className="w-full md:w-max  mt-4 md:mt-0"
          >
            <button
              className={`bg-primary-800 c-btn-primary w-full md:w-max text-white`}
              style={{
                fontSize: "18px",
              }}
            >
              <PlusOutlined className=" mr-2" />
              Create Async Interview
            </button>
          </Link>
        )}
      </div>
      <hr className="mb-7" />
      {isLoading ? (
        <div className="flex justify-center items-center my-13">
          <LoadingScreen />
        </div>
      ) : (
        <div className="card-container">
          <Tabs type="card" onChange={handleTabChange} activeKey={activeTab}>
            <TabPane
              tab={<div className="text-base">Async Interviews </div>}
              key="1"
            >
              <div
                className="m-0 min-w-full w-full border rounded-lg px-2 pt-1 shadow divide-gray-200"
                id="custom-table"
              >
                <Table
                  columns={columns}
                  dataSource={interviews}
                  scroll={{ x: 1000 }}
                  rowKey={(record) => record.uuid}
                />
              </div>
            </TabPane>

            <TabPane
              tab={<div className="text-base">Sync Interviews</div>}
              key="3"
              className="max-w-full"
            >
              <div className="m-0 border shadow rounded-lg px-2 pt-1 min-w-full max-w-full divide-gray-200">
                <Table
                  columns={eventColumns}
                  dataSource={events}
                  scroll={{ x: 1000 }}
                  rowKey={(record) => record.id}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default null;