import { Tooltip, Modal, Form, Input, message } from "antd";
import { ExclamationCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";

const DuplicateButton = ({ record, handleDuplicate }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [title, setTitle] = useState("");
	const [form] = Form.useForm();

	useEffect(() => {
		setTitle(`Copy of ${record.title}`);
	}, [record.title]);

	const showDuplicateModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = async () => {
		try {
			await form.validateFields();
			handleDuplicate(record.id, title);
			setIsModalVisible(false);
		} catch (error) {
			message.error({
				key: "duplicate",
				content: "Title is required",
			});
		}
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return (
		<Tooltip title="Duplicate Interview" color="#21BCAB" key={record.uuid + "4"}>
			<button
				className="btn-icon hover-green"
				type="info"
				onClick={showDuplicateModal}
			>
				<CopyOutlined />
			</button>

			<Modal
				title={`Duplicate Interview ${record.title}`}
				centered
				visible={isModalVisible}
				icon={<ExclamationCircleOutlined />}
				onOk={handleOk}
				onCancel={handleCancel}
				okText="Submit"
				cancelText="Cancel"
			>
				<Form
					form={form}
					initialValues={{ title: title }}
				>
					<Form.Item
						label="Title"
						name="title"
						rules={[{ required: true, message: "Please enter a title!" }]}
					>
						<Input
							onChange={(e) => setTitle(e.target.value)}
							placeholder="Enter title for the interview"
						/>
					</Form.Item>
				</Form>
			</Modal>
		</Tooltip>
	);
};

export default DuplicateButton;
