import API from "./axios";

export const getInterviews = (type) => {
  return type
    ? API.get("/rooms/events-interviews-with-sso/", {
      params: {
        is_deleted: true,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("api_key")}`,
      },
    })
    : API.get("/rooms/events-interviews-with-sso/", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("api_key")}`,
      },
    });
};

export const publishInterview = (data) => {
  return API.post("/interviews/with-sso/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};

export const updateInterview = (id, data) => {
  return API.patch(`/interviews/with-sso/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};
export const getInterview = (id) => {
  return API.get(`/interviews/with-sso/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};

export const getInterviewQuestions = (uuid) => {
  return API.get(`/interviews/`, {
    params: {
      uuid,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};

export const deleteInterview = (id) => {
  return API.delete(`/interviews/with-sso/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};

export const duplicateInterview = (data) => {
  return API.post(`interviews/with-sso/duplicate/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("api_key")}`,
    },
  });
};

const blobToUrl = (blob) => {
  return URL.createObjectURL(new Blob([blob], { type: "video/mp4" }));
};

export const uploadAnswer = (
  type,
  { question_id, interview_id, candidate_id },
  data,
  isDemo
) => {
  if (isDemo === true) {
    return new Promise((resolve, reject) => {
      const tempDemoResponse = {
        id: 4,
        uuid: "bf92f651-8695-45a6-8d65-18aeb88c6e52",
        interview_id: 3,
        candidate_id: 1,
        question_id: 2,
        file: blobToUrl(data),
        filename: "interview_id_39_candidate_id_558_question_id_106.mp4",
        url: blobToUrl(data),
        created: "2023-07-14T07:50:41.690485Z",
        modified: "2023-07-14T07:50:41.690510Z",
        question: {
          id: 2,
          uuid: "44444-4444-4-4",
          title: "How are you today?",
          interview_id: 3,
          video_duration: "00:01:00",
          response_type: "video",
          retakes_allowed: 5,
          created: "2023-05-27T07:57:40.515039Z",
          modified: "2023-05-27T07:57:40.515068Z",
          is_deleted: false,
        },
        text_response: null,
      };

      setTimeout(() => {
        resolve({ data: tempDemoResponse });
      }, 1500);
    });
  } else {
    const formData = new FormData();
    if (type === "text") {
      formData.append("text_response", data);
    } else {
      const file = new Blob([data], { type: "video/mp4" });
      const fileName = `interview_id_${interview_id}_candidate_id_${candidate_id}_question_id_${question_id}.mp4`;
      formData.append("file", file, fileName);
    }
    formData.append("question_id", question_id);
    formData.append("interview_id", interview_id);
    formData.append("candidate_id", candidate_id);
    return API.post(`/interviews/attachments/`, formData);
  }
};

export const submitInterview = (interview_id, data) => {
  return API.post("/interviews/response/", data, {
    params: {
      interview_id: interview_id,
    },
  });
};

export const waitForInterview = (time) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};
